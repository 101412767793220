@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap');

:root {
  --primary: #0fcc7c;
  --secondary: #00384f;
  --tertiary: #e1f5ec;
  --text: #00384f;
  --card-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}

html {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

.rotate-center {
  -webkit-animation: rotate-center 2s ease-in-out both infinite;
  animation: rotate-center 2s ease-in-out both infinite;
}

.zoom {
  -webkit-animation: zoom 10s ease-in-out both infinite;
  animation: zoom 10s ease-in-out both infinite;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  35% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  35% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--tertiary);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary);
}

.active {
  border-right: 8px solid var(--primary) !important;
  background-color: var(--tertiary) !important;
}

.nav {
  width: 100%;
}
